<template functionnal>
  <div class="template pb-4">
    <div class="template__content">
      <div class="row">
        <div class="col-12">
          <h1>Nos formations | <span class="marker-effect">Cours d'alphabétisation</span></h1>
        </div>
      </div>

      <div class="row">
        <div class="col-6 mt-4">
          <div class="col p-0 mt-1">
            <h5>Alphabétisation pour allophones</h5>
            <div>Action REFUG</div>
          </div>
          <div class="row">
            <div class="font-bold">Public et prérequis</div>
            Demandeurs d’asile ou réfugiés statutaires, résidents en centre d’hébergement d’urgence
            parisien, peu ou pas scolarisés dans leur pays d’origine, non lecteurs, non scripteurs,
            grand débutant à l’oral.
          </div>
          <div class="row">
            <div class="font-bold">Objectifs</div>
            Permettre à un public non lecteur/non scripteur d’acquérir les bases de la lecture et de
            l’écriture du français à travers un enseignement basé sur une méthode syllabique axée
            sur les besoins du quotidien.
          </div>

          <div class="row mt-8">
            <img
              src="@/assets/images/04-alpha-1.jpg"
              style="height: 383px; width: 100%"
              alt="cour de francais visee pro"
            />
          </div>

          <div class="row mt-5">
            <h6>Descriptions des cours</h6>

            Action en partenariat avec l’association FLE et Compagnie composée de 12 h de cours
            hebdomadaires dont des ateliers de conversation et d’informatique et des sorties
            culturelles.
            <ul>
              <li><span class="font-semibold">Horaires :</span> lundi au jeudi de 14h à 17h</li>
              <li><span class="font-semibold">Tarif :</span> gratuit</li>
              <li>
                <span class="font-semibold">Durée, volume horaire :</span> octobre à juin (270h)
              </li>
              <li>
                <span class="font-semibold">Lieu du cours :</span> Association Femmes Initiatives
              </li>
              <li>
                <span class="font-semibold">Les modalités d’inscription:</span>
                <router-link to="contact">nous contacter</router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-6 mt-4">
          <div class="col p-0">
            <h5>Renforcement en alphabétisation à visée professionnelle</h5>
            <span>Action BOP104-Action 12</span>
          </div>
          <div class="row">
            <div class="font-bold">Public et prérequis</div>
            Réfugiés statutaires bénéficiaires de la protection internationale, peu ou pas
            scolarisés dans leur pays d’origine, ayant déjà effectué le parcours OFII.
          </div>
          <div class="row">
            <div class="font-bold">Objectifs</div>
            Permettre à un public non lecteur/non scripteur de renforcer les bases de la lecture et
            de l’écriture du français acquis préalablement dans les cours obligatoires de l’Ofii à
            travers un enseignement basé sur une méthode syllabique axée sur les besoins du
            quotidien.
          </div>

          <div class="row mt-5">
            <img src="@/assets/images/05-alpha-2.jpeg" alt="cour de francais visee pro" />
          </div>

          <div class="row mt-5">
            <h6>Descriptions des cours</h6>

            Action en partenariat avec l’association FLE et Compagnie composée de 9h de cours
            hebdomadaires, d’atelier de conversation et d’informatique (4h/semaine) et de sorties
            culturelles.
            <ul>
              <li>
                <span class="font-semibold">Horaires :</span> lundi, mercredi, vendredi de 10h à 13h
                et mardi, jeudi de 10h à 12h
              </li>
              <li><span class="font-semibold">Tarif :</span> gratuit</li>
              <li>
                <span class="font-semibold">Durée, volume horaire :</span> octobre à mai (351h)
              </li>
              <li>
                <span class="font-semibold">Lieu du cours :</span> Association Femmes Initiatives
              </li>
              <li>
                <span class="font-semibold">Les modalités d’inscription:</span>
                <router-link to="contact">nous contacter</router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-12 text-center">
          <a href="/alphabetisation.pdf" download>Télécharger la plaquette</a>
        </div>
      </div>
    </div>
  </div>
</template>
